
// @ts-nocheck
import ArtHallLogo from '~/components/new/blocks/ArtHallLogo.vue'
import LoginForm from '~/components/new/forms/Login.vue'
import Notice from '~/components/new/blocks/Notice.vue'
import BaseButton from '~/components/new/ui/BaseButton.vue'

export default {
  components: { ArtHallLogo, LoginForm, Notice, BaseButton },
  data () {
    return {
      invalidData: false,
      loading: false,
      isSelecting: false,
      notice: {
        title: 'Ваша заявка проходит отбор',
        subtitle: 'Ваша заявка отправлена на проверку. Обычно, это занимает от 2 до 7 дней.</br> После завершения отбора мы отправим вам результаты на указанную вами электронную почту'
      }
    }
  },
  head () {
    return {
      title: this.$t('head.title.login'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('head.description.login')
        },
        { hid: 'og:title', name: 'og:title', content: this.$t('head.title.login') },
        { hid: 'og:description', name: 'og:description', content: this.$t('head.description.login') },
        { hid: 'og:url', name: 'og:url', content: process.env.API_ENDPOINT + this.$route.fullPath }
      ]
    }
  },
  methods: {
    async login (payload) {
      // this.invalidData = false
      this.loading = true
      try {
        const loginResponse = await this.$axios.post(
          process.env.API_ENDPOINT + '/api/users/login',
          payload
        )

        if (loginResponse.status === 200) {
          localStorage.setItem('storedToken', loginResponse.data.token)
          this.$router.replace('/profile')
        }
        console.log(loginResponse, 'loginResponse')
      } catch {
        this.invalidData = true
        this.loading = false
      }
      this.loading = false
    }
  },
  logout () {
    this.isSelecting = false
    localStorage.removeItem('storedToken')
  }
}
